namespace Shared.EditorTemplates.Signature2
{
    const setupControls = () =>
    {
        const classNameControlSetup = "controlSetup";
        const $signatureControls = getJQuery({ containerFor: "signatureControl" }).not(`.${classNameControlSetup}`);

        $signatureControls.each((_: number, signatureControl: Element) => setupControl($(signatureControl)));

        function setupControl($signatureControl: JQuery)
        {
            const $displaySignature = $signatureControl.getJQuery({ controlFor: "displaySignature" });
            const $output = $signatureControl.getJQuery({ controlFor: "output" });
            $displaySignature.addClickEvent(showSignatureModal);

            $output.on("change", drawSignature);

            getJQuery({ containerFor: "fontPreLoaderContainer" }).setVisibility(false);

            $signatureControl.addClass(classNameControlSetup);

            drawSignature();

            function drawSignature()
            {
                const canvas = ($displaySignature[0] as HTMLCanvasElement);
                const context = canvas.getContext("2d");
                const signature = $output.getString();

                context.clearRect(0,
                    0,
                    canvas.width,
                    canvas.height);

                if (signature != null && signature !== "") {
                    if (signature.lastIndexOf("data:image/", 0) === 0) {
                        const image = new Image;
                        image.onload = () =>
                        {
                            context.drawImage(image, 0, 0);
                        };
                        image.src = signature;
                    } else {
                        const signatureLines: ISignatureLine[] = JSON.parse(signature);

                        for (let index = 0; index < signatureLines.length; index++) {
                            const signatureLine = signatureLines[index];

                            context.beginPath();
                            context.moveTo(signatureLine.mx, signatureLine.my);
                            context.lineTo(signatureLine.lx, signatureLine.ly);
                            context.stroke();
                            context.closePath();
                        }
                    }
                }
                else
                {
                    const stringToPrint = "Click Here To Sign";
                    const gutter = 15;
                    const canvasWidth = canvas.width;
                    const canvasHeight = canvas.height;
                    context.clearRect(0, 0, canvasWidth, canvasHeight);
                    context.font = "30px Arial";
                    context.fillStyle = "#D3D3D3";
                    context.fillText(stringToPrint,
                        gutter,
                        canvasHeight - (gutter * 2),
                        canvasWidth - (gutter * 2));
                }
            }

            function showSignatureModal()
            {
                Modals.show({ requestOptions: { controller: $displaySignature.data("controller"), action: $displaySignature.getAction() }, onShown });

                function onShown($modal: JQuery)
                {
                    const $drawTab = getJQuery({ controlFor: "drawTab" });
                    const $typeTab = getJQuery({ controlFor: "typeTab" });
                    const $drawSignatureTab = $modal.getJQuery({ containerFor: "drawSignatureTab" });
                    const $typeSignatureTab = $modal.getJQuery({ containerFor: "typeSignatureTab" });
                    const $signatureDrawingContainer = $drawSignatureTab.getJQuery({ containerFor: "signatureDrawingContainer" });
                    const $signatureTypeCanvasContainer = $typeSignatureTab.getJQuery({ containerFor: "signatureTypeCanvasContainer" });
                    const $signatureTypeCanvas = $signatureTypeCanvasContainer.getJQuery({ controlFor: "signatureTypeCanvas" });
                    const signatureTypeCanvas = $signatureTypeCanvas[0] as HTMLCanvasElement;

                    $drawTab.on('shown.bs.tab', setupSignatureDrawing);
                    $typeTab.on('shown.bs.tab', setupSignatureTyping);

                    $drawTab.tab("show");

                    function setupSignatureDrawing() 
                    {
                        if (!$drawSignatureTab.data("setup"))
                        {
                            $signatureDrawingContainer.jSignature("init", { UndoButton: true });
                            $drawSignatureTab.getJQuery({ controlFor: "clear" }).addClickEvent(clear);

                            function clear()
                            {
                                $signatureDrawingContainer.jSignature("reset");
                            }

                            $drawSignatureTab.data("setup", true);
                        }
                    }

                    function setupSignatureTyping()
                    {
                        const $typeSignature = $typeSignatureTab.getJQuery({ controlFor: "typeSignature" });
                        $typeSignature.focus();

                        if (!$typeSignatureTab.data("setup"))
                        {
                            $typeSignature.on("change keyup paste", updateSignature);
                            signatureTypeCanvas.height = $signatureTypeCanvasContainer.height();
                            signatureTypeCanvas.width = $signatureTypeCanvasContainer.width();
                            const canvasContext = signatureTypeCanvas.getContext("2d");
                            const canvasWidth = signatureTypeCanvas.width;
                            const canvasHeight = signatureTypeCanvas.height;
                            canvasContext.font = "100px 'Homemade Apple'";
                            canvasContext.fillStyle = "black";

                            function updateSignature()
                            {
                                const stringToPrint = $typeSignature.getString();
                                const gutter = 30;
                                canvasContext.clearRect(0, 0, canvasWidth, canvasHeight);
                                canvasContext.fillText(stringToPrint,
                                    gutter,
                                    canvasHeight - (gutter * 2),
                                    canvasWidth - (gutter * 2));
                            }

                            $typeSignatureTab.data("setup", true);
                        }
                    }

                    const $ok = $modal.getJQuery({ controlFor: "ok" });
                    $ok.addClickEvent(ok);

                    function ok()
                    {
                        Validation.clearAlerts($modal);

                        const activeClass = "active";

                        let sourceCanvas: HTMLCanvasElement = null;
                        let validationFor = "";

                        if ($drawSignatureTab.hasClass(activeClass))
                        {
                            validationFor = $signatureDrawingContainer.data("validation-for");
                            sourceCanvas = $signatureDrawingContainer.find("canvas")[0] as HTMLCanvasElement;
                        }
                        else if ($typeSignatureTab.hasClass(activeClass)) 
                        {
                            validationFor = $signatureTypeCanvas.data("validation-for");
                            sourceCanvas = signatureTypeCanvas;
                        }

                        if (sourceCanvas == null || isCanvasBlank(sourceCanvas)) 
                        {
                            const errors: Validation.Error[] = [{ Key: validationFor, Message: "Please provide a valid signature" }];
                            Validation.showAlerts(errors, $modal);
                        }
                        else 
                        {
                            const sourceCanvasWidth = sourceCanvas.width;
                            const sourceCanvasHeight = sourceCanvas.height;
                            const displaySignatureCanvas = $displaySignature[0] as HTMLCanvasElement;
                            const displaySignatureCanvasContext = displaySignatureCanvas.getContext("2d");
                            const tempCanvas =
                                $("<canvas>").attr("width", sourceCanvasWidth).attr("height", sourceCanvasHeight)[0] as
                                HTMLCanvasElement;
                            const tempCanvasContext = tempCanvas.getContext("2d");

                            const widthScale = displaySignatureCanvas.width / sourceCanvasWidth;
                            const heightScale = displaySignatureCanvas.height / sourceCanvasHeight;

                            let scale = 0;

                            if (widthScale < heightScale)
                            {
                                scale = widthScale;
                            } else
                            {
                                scale = heightScale;
                            }

                            tempCanvasContext.scale(scale, scale);
                            tempCanvasContext.drawImage(sourceCanvas, 0, 0);

                            displaySignatureCanvasContext.clearRect(0,
                                0,
                                displaySignatureCanvas.width,
                                displaySignatureCanvas.height);
                            displaySignatureCanvasContext.drawImage(tempCanvas, 0, 0);

                            $output.val(displaySignatureCanvas.toDataURL());

                            Modals.close($modal);
                        }

                        function isCanvasBlank(canvas: HTMLCanvasElement): boolean
                        {
                            return !canvas.getContext("2d")
                                .getImageData(0, 0, canvas.width, canvas.height).data
                                .some(channel => channel !== 0);
                        }
                    }
                }
            }
        }
    }

    interface ISignatureLine
    {
        lx: number;
        ly: number;
        mx: number;
        my: number;
    }

    export const documentReady = () =>
    {
        setupControls();
    }

    export const documentAJAXComplete = () =>
    {
        setupControls();
    }
}

$(document).ready(Shared.EditorTemplates.Signature2.documentReady);
$(document).ajaxComplete(Shared.EditorTemplates.Signature2.documentAJAXComplete);